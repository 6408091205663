
import LoadingSpinner from '../loading-spinner.vue';
import modals from '../../mixins/modals';
import CompanyCIKsModal from './manage-ciks-modal.vue';
import optionButtonTooltip from '~/mixins/option-button-tooltip';

export default {
    name: 'HoldingsCard',
    components: { CompanyCIKsModal, LoadingSpinner },
    mixins: [optionButtonTooltip, modals],
    props: {
        company: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            loading: false,
            onlyLifeSciences: false,
            holdings: [],
            config: {
                columns: [
                    {
                        headline: 'Name'
                    }, {
                        headline: 'Ticker'
                    }, {
                        headline: 'Shares'
                    }, {
                        headline: 'Value'
                    }, {
                        headline: 'Date'
                    }, {
                        headline: 'Security Name',
                        breakpoint: 'all'
                    }
                ],
                pagination: 10
            },
            modal: null,
            cikModalKey: 'cikModalKey'
        };
    },
    computed: {
        holdingRows() {
            return this.holdings.map((holding) => {
                return {
                    id: holding.id,
                    cells: [
                        { type: 'companyName', holding },
                        { type: 'companyTicker', holding },
                        { type: 'text', text: this.nFormatter(holding.sharesNumber) },
                        { type: 'text', text: this.nFormatter(holding.marketValue) },
                        { type: 'text', text: this.formatDate(holding.date) },
                        {
                            text: holding.securityName
                        }
                    ]
                };
            });
        }
    },
    watch: {
        onlyLifeSciences() {
            this.loadHoldings();
        }
    },
    mounted() {
        this.loadHoldings();
    },
    methods: {
        loadHoldings() {
            this.loading = true;
            this.$axios.get(`/api/companies/holdings/${this.company.id}?`
                + this.buildQueryString({ onlyLifeSciences: this.onlyLifeSciences }))
                .then((response) => {
                    this.holdings = response.data;
                }).finally(() => {
                this.loading = false;

            });
        },
        async manageCIKs() {
            this.cikModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.companyCIKModal);
        },
        onCIKModalClosed() {
            if (this.modal) {
                this.modal.hide();
                this.modal = null;
            }
            this.loadHoldings();
        }
    }
};
