import { render, staticRenderFns } from "./manage-ciks-modal.vue?vue&type=template&id=1c883442&scoped=true"
import script from "./manage-ciks-modal.vue?vue&type=script&lang=js"
export * from "./manage-ciks-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c883442",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src2285020857/src/web/components/save-button.vue').default})
